<template>
  <settings>
    <template v-slot:content>
      <v-container>
        <v-list>
          <draggable
            :value="workspaces"
            @input="$store.commit('workspace/setWorkspaces', $event)"
            handle=".drag-handle"
            @end="sort"
          >
            <v-list-item v-for="workspace in workspaces" :key="workspace.id">
              <v-list-item-icon>
                <v-icon class="drag-handle">mdi-drag</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ workspace.name }}</v-list-item-title>
              </v-list-item-content>

              <template v-if="workspace.is_owner == 1">
                <v-list-item-action>
                  <v-btn icon @click="editWorkspace(workspace)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-list-item-action>

                <v-list-item-action>
                  <v-btn
                    icon
                    @click="deleteWorkspace(workspace)"
                    :disabled="workspaces.length <= 1"
                  >
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </template>
            </v-list-item>
          </draggable>
        </v-list>
      </v-container>

      <v-btn fab fixed bottom right color="primary" @click="createWorkspace">
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <edit-workspace-dialog ref="editWorkspaceDialog" />
      <confirm ref="confirmDeleteDialog" :text="deleteDialogText" ok="Delete" buttonColor="error" />
    </template>

  </settings>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import draggable from 'vuedraggable';
import Confirm from '../../components/dialog/Confirm.vue';
import EditWorkspaceDialog from '../../components/workspace/dialog/EditWorkspaceDialog.vue';
import Settings from '../../layouts/Settings.vue';

export default {
  components: {
    Settings,
    EditWorkspaceDialog,
    Confirm,
    draggable,
  },

  data() {
    return {
      deleteDialogText: '',
    };
  },

  computed: {
    ...mapState('workspace', ['workspaces']),
  },

  methods: {
    ...mapActions('workspace', ['listWorkspaces', 'save', 'delete', 'sort']),

    async createWorkspace() {
      try {
        const data = await this.$refs.editWorkspaceDialog.open();
        await this.save(data);
        this.listWorkspaces();
      } catch {
      //
      }
    },

    async editWorkspace(workspace) {
      try {
        const data = await this.$refs.editWorkspaceDialog
          .open(JSON.parse(JSON.stringify(workspace)));
        await this.save(data);
        this.listWorkspaces();
      } catch {
      //
      }
    },

    async deleteWorkspace(workspace) {
      try {
        this.deleteDialogText = `Are you sure you want to delete "${workspace.name}"?`;
        await this.$refs.confirmDeleteDialog.open();
        await this.delete(workspace);
        this.listWorkspaces();
      } catch {
        //
      }
    },
  },

  mounted() {
    this.listWorkspaces();
  },
};
</script>
