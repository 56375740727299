<template>
  <div>
    <v-navigation-drawer
      permanent
      fixed
      floating
      color="transparent"
    >
      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.to"
          exact
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <div style="padding-left: 256px;">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Kode-hub',
    },
  },

  data() {
    return {
      items: [
        {
          title: 'Back',
          icon: 'mdi-chevron-left',
          to: this.$store.state.settingsBackRoute
            ? this.$store.state.settingsBackRoute
            : { name: 'Home' },
        },
        { title: 'Workspaces', icon: 'mdi-account-box-multiple', to: { name: 'SettingsWorkspaces' } },
      ],
    };
  },
};
</script>
